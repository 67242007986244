<template>

<app-content :loading="is.loading && !is.initialised">

	<app-content-head title="Browse" subtitle="See which games have been checked out by badge holders.">

		<app-content-head-actions-panel v-if="collection.length" text="Export" icon="export" component="manager/common/panel/Export" :data="importexportData" />
		<app-content-head-actions-item v-if="$authorised('con/checkout/access', 'write')" :divide="true" text="Returned" icon="returned" :disabled="!selected.length" v-on:click="onReturnClick" />
		<app-content-head-actions-item v-if="$authorised('con/checkout/access', 'write')" text="Delete" icon="delete" :danger="true" :disabled="!selected.length" v-on:click="onDeleteClick" />

	</app-content-head>

	<app-content-filters>

		<app-content-filters-search v-model="filter.search" />

		<app-content-filters-tags v-model="filter.tags" :options="filterTagsOptions" />
		<app-content-filters-select v-model="filter.sort" text="Sort by" tooltip="Change sorting" :options="filterSortOptions" />

	</app-content-filters>

	<app-content-body :loading="is.loading && is.initialised" :is-empty="!collection.length" placeholder="Sorry, no loans found.">

		<app-table>

			<app-table-head :layout="layout">

				<app-table-column text="Game" />
				<app-table-column text="Badge" />
				<app-table-column :sorted="filter.sort === 'hours'" align="center" text="Hours" />
				<app-table-column :sorted="filter.sort === 'date.out'" text="Checked Out" />
				<app-table-column :sorted="filter.sort === 'date.in'" text="Checked In" />
				<app-table-column />

			</app-table-head>

			<app-table-body>

				<app-table-row :warning="item.status === $constants.checkout.loan.status.active" :danger="item.status === $constants.checkout.loan.status.active && item.hours > $convention.settings.checkout.limit.hours.amount && $convention.settings.checkout.limit.hours.enabled" :selected="isSelected(item.id)" :layout="layout" v-for="item in collection" :key="item.id">

					<app-table-cell-text :text="item.game.name" :subtext="item.game.version" />
					<app-table-cell-text :text="item.badge.name" :subtext="'#' + item.badge.reference" />
					<app-table-cell-text align="center" :text="item.hours" />
					<app-table-cell-text :text="item.date.out | localDate('h:mma')" :subtext="item.date.out | localDate('dddd')" />
					<app-table-cell-text :can-empty="true" :text="item.date.in | localDate('h:mma')" :subtext="item.date.in | localDate('dddd')" />
					<app-table-cell-select :active="isSelected(item.id)" v-on:click="onSelectToggle(item.id)" />

				</app-table-row>

			</app-table-body>

			<app-table-foot :pagination="pagination" :total="total" />

		</app-table>

	</app-content-body>

</app-content>

</template>

<script>

import mixCollection from '@/mixin/collection'

export default {

	mixins: [mixCollection],

	data: function() {

		return {
			references: {
				sorts: [],
				tags: []
			},
			filter: {
				tags: [],
				sort: 'date.out'
			},
			layout: 'auto 300px 100px 100px 100px 24px',
			endpoint: 'convention/checkout/loans',
			live: 'convention/checkout/loans',
			edit: 'Convention.Checkout.Loans.Edit'
		}

	},

	computed: {
		
		importexportData: function() {

			return {
				columns: {
					'status': 'Status',
					'gameReference': 'Game Reference',
					'gameName': 'Game Name',
					'gameVersion': 'Game Version',
					'badgeReference': 'Badge Reference',
					'badgeName': 'Badge Name',
					'dateOut': 'Checked Out Date',
					'dateIn': 'Checked Out Date',
					'hours': 'Hours Loaned'
				},
				requiredColumns: [],
				valueMapping: {},
				params: this.getParams(),
				index: this.selectedIndex,
				endpoint: this.endpoint
			}

		},

		filterSortOptions: function() {

			var options = []

			this.$_.each(this.references.sorts, function(text, value) {

				options.push({
					value: value,
					text: text
				})
			
			})

			return this.$_.sortBy(options, 'text')

		},

		filterTagsOptions: function() {

			var options = []

			this.$_.each(this.references.tags, function(text, value) {

				options.push({
					value: value,
					text: text
				})
			
			}.bind(this))

			return this.$_.sortBy(options, 'text')

		}

	},

	methods: {

		onReturnClick: function() {

			this.$api.patch(this.endpoint, {
				index: this.selected,
				model: {
					status: this.$constants.checkout.loan.status.returned
				}
			}).then(function() {

				this.selected = []

			}.bind(this))

		}

	}

}

</script>

<style scoped>

</style>